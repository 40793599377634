@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Actor&family=Asap&family=Reem+Kufi+Fun:wght@400..700&family=Ubuntu&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(236, 236, 255);
  color: rgb(130, 130, 138); */
  /* background-color: rgb(207, 207, 207); */
  color: rgb(130, 130, 138);
  /* background-color: rgb(238, 238, 238); */
  background-color: #f3f3f3;

}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */

}

.no-blur {
  backface-visibility: hidden;
  transform: translateZ(0) scale(.99, .99) perspective(1px);
  -webkit-font-smoothing: subpixel-antialiased;
}

@layer components {
  .btn-primary {
    @apply bg-logo-pink hover:bg-mb-red duration-500 px-5 py-[7px] rounded-full text-white font-semibold transition-colors;
  }

  .btn-secondary {
    @apply hover:border-mb-red px-5 py-[7px] duration-500 rounded-full border border-logo-pink font-semibold transition-colors;
  }

  .accent-bar {
    @apply h-2 w-32 bg-logo-pink mt-16;
  }

  .heading {
    @apply mt-5 font-header font-bold text-3xl text-black pb-6 tracking-wider;
  }

  .subheading {
    @apply  font-header font-bold text-xl text-black tracking-wider;
  }

  .active-page {
    @apply underline decoration-4 underline-offset-8 decoration-logo-pink;
  }

  .popup {
    @apply bg-white shadow-lg rounded-md fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 gap-6 min-w-72 z-50 flex flex-col items-center text-center;

  }

  .backdrop {
    @apply bg-backdrop fixed top-0 left-0 w-full h-full backdrop-blur-sm z-30;

  }


}

.pricing-grid {
  grid-template-columns: 150px auto;
  /* align-self: start; */
  display: flex;
  justify-content: space-between;

  
}

.video-iframe {
  max-width: 800px;
  margin: 50px auto 5vh auto;
  aspect-ratio: 3.555/2;

}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  
  border-top: 15px solid rgb(71, 71, 71);
}



.masked:last-child > span {
  mask: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))

}

.mask {
  mask: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))
}

.mask-30 {
  mask: linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0) );
 
}

.form-item {
  margin-bottom: 20px;
  flex: auto;
}

.form-label {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  /* color: #1a3b5d; */
  width: 100%;
  display: block;
  user-select: none;
  color: #56596d;
  text-align: left;
}

.form-input {
  width: 100%;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  font-family: 'Source Sans Pro', sans-serif;
  outline: none;
  background-color: white;
  scrollbar-width: none;
}

.form-input:hover, .form-input:focus, .form-input:active {
  border-color: #3d9cff !important;
}

.form-input:focus {
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);

}

.error-border {

    border: 1px solid #fb0023;

}

.duration-point-five {
  transition: all .5s ease-in-out;
}

.duration-point-three {
  transition: all .3s ease-in-out;
}

.swipe-slide {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;

}

.maryland-permission {
  text-align: center;
}